import { Component, OnInit } from '@angular/core';
import { FileService } from "../services/fileservice.service";

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})
export class SliderComponent implements OnInit {

	public slides: any[];
	public interval: any;
	public slideCount: number = 0;

  constructor(private fileService: FileService) {
    this.fileService.imageListUpdated.subscribe(
      (data) => {
        if (data.length > 0)
          this.slides = data;
      }
    )
  }

  ngOnInit() {
    this.slides = [{ src: './assets/img/bay_1200_500.jpg', label: '', content: ''}];
    this.fileService.getPictureList();
    // this.interval = setInterval(() => { this.addSlide()}, 2000);
  }

  // addSlide() {
  //   this.slides.push({ src: 'https://lorempixel.com/1200/500?r=1', label: '', content: ''});
  //   this.slideCount ++;
  //   if (this.slideCount > 4)
  //   {
  //     clearInterval(this.interval);
  //   }
  // }

}
