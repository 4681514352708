import { Component, Input, OnInit } from '@angular/core';
import { DateModel } from "../services/date.model";

@Component({
  selector: 'app-calendarbox',
  templateUrl: './calendarbox.component.html',
  styleUrls: ['./calendarbox.component.scss']
})
export class CalendarboxComponent implements OnInit {

  @Input('data') dateData : DateModel;

  constructor() { }

  ngOnInit() {
  }

}
