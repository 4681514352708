import {Component, OnInit} from '@angular/core';
import {DataService} from "../services/dataservice.service";
import {FileService} from "../services/fileservice.service";
import {FileModel} from "../services/file.model";

@Component({
	selector: 'app-downloads',
	templateUrl: './downloads.component.html',
	styleUrls: ['./downloads.component.css']
})
export class DownloadsComponent implements OnInit {

	public intro: string = '';
	public heading: string = '';
	public defaultHeading: string = 'Essential Downloads';
	public files: FileModel[] = [];

	constructor(private dataService: DataService, private fileService: FileService)
	{

		this.dataService.dataServiceArrived.subscribe(
			(data) =>
			{
				if (data.filter((data) =>
				{
					return data.Type == 'intro-downloads'
				}).length > 0) this.intro = data.filter((data) =>
				{
					return data.Type == 'intro-downloads'
				})[0].Content;
				if (data.filter((data) =>
				{
					return data.Type == 'title-downloads'
				}).length > 0) this.heading = data.filter((data) =>
				{
					return data.Type == 'title-downloads'
				})[0].Content.replace(/(<([^>]+)>)/gi, "");
			}
		);
		this.fileService.fileListUpdated.subscribe(
			(files: FileModel[]) =>
			{
				this.files = files;
			}
		)
	}

	ngOnInit()
	{
		this.dataService.getContentTopics('title-downloads');
		this.dataService.getContentTopics('intro-downloads');
		this.fileService.getFileList();
	}

}
