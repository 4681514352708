import {Component, OnInit} from '@angular/core';
import {DataService} from "../services/dataservice.service";
import {DateService} from "../services/dateservice.service";
import {DateModel} from "../services/date.model";

@Component({
	selector: 'app-dates',
	templateUrl: './dates.component.html',
	styleUrls: ['./dates.component.css']
})
export class DatesComponent implements OnInit {

	public intro: string = '';
	public heading: string = '';
	public defaultHeading: string = 'Big dates coming up';
	public dates: DateModel[] = [];

	constructor(private dataService: DataService, private dateService: DateService)
	{

		this.dataService.dataServiceArrived.subscribe(
			(data) =>
			{
				if (data.filter((data) =>
				{
					return data.Type == 'intro-events'
				}).length > 0) this.intro = data.filter((data) =>
				{
					return data.Type == 'intro-events'
				})[0].Content;
				if (data.filter((data) =>
				{
					return data.Type == 'title-events'
				}).length > 0) this.heading = data.filter((data) =>
				{
					return data.Type == 'title-events'
				})[0].Content.replace(/(<([^>]+)>)/gi, "");
			}
		)
		this.dateService.dateSubject.subscribe(
			(dates: DateModel[]) =>
			{
				this.dates = dates;
			}
		)
	}

	ngOnInit()
	{
		this.dataService.getContentTopics('title-events');
		this.dataService.getContentTopics('intro-events');
		this.dateService.getDates();
	}

}
