import {Component, OnInit} from '@angular/core';
import {DataService} from "../services/dataservice.service";

@Component({
	selector: 'app-sponsors',
	templateUrl: './sponsors.component.html',
	styleUrls: ['./sponsors.component.css']
})
export class SponsorsComponent implements OnInit {

	public intro: string = '';
	public heading: string = '';
	public defaultHeading: string = 'Some of our fantastic sponsors';

	constructor(private dataService: DataService)
	{

		this.dataService.dataServiceArrived.subscribe(
			(data) =>
			{
				if (data.filter((data) =>
				{
					return data.Type == 'intro-sponsors'
				}).length > 0) this.intro = data.filter((data) =>
				{
					return data.Type == 'intro-sponsors'
				})[0].Content;
				if (data.filter((data) =>
				{
					return data.Type == 'title-sponsors'
				}).length > 0) this.heading = data.filter((data) =>
				{
					return data.Type == 'title-sponsors'
				})[0].Content.replace(/(<([^>]+)>)/gi, "");
			}
		)
	}

	ngOnInit()
	{
		this.dataService.getContentTopics('title-sponsors');
		this.dataService.getContentTopics('intro-sponsors');
	}

}
