import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';

import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import { HomeComponent } from './main/home/home.component';
import { NewsComponent } from './main/news/news.component';
import { DownloadsComponent } from './main/downloads/downloads.component';
import { SponsorsComponent } from './main/sponsors/sponsors.component';
import { DatesComponent } from './main/dates/dates.component';
import { AboutComponent } from './main/about/about.component';
import { PrizesComponent } from './main/prizes/prizes.component';
import { AppRouting } from "./app-routing.module";
import { MenuComponent } from './main/menu/menu.component';
import { RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { SliderComponent } from './main/slider/slider.component';
import { FileService } from "./main/services/fileservice.service";
import { DataService } from "./main/services/dataservice.service";
import { CalendarboxComponent } from './main/calendarbox/calendarbox.component';
import { DateService } from "./main/services/dateservice.service";
import {WinnersComponent} from "./main/winners/winners.component";

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    HomeComponent,
    NewsComponent,
    DownloadsComponent,
    SponsorsComponent,
    DatesComponent,
    AboutComponent,
    PrizesComponent,
    MenuComponent,
    SliderComponent,
    CalendarboxComponent,
    WinnersComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpModule,
    RouterModule,
    NgbModule.forRoot(),
    AppRouting
  ],
  providers: [FileService, DataService, DateService],
  bootstrap: [AppComponent]
})
export class AppModule { }
