import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HomeComponent } from "./main/home/home.component";
import { MainComponent } from "./main/main.component";
import { AboutComponent } from "./main/about/about.component";
import { NewsComponent } from "./main/news/news.component";
import { DownloadsComponent } from "./main/downloads/downloads.component";
import { SponsorsComponent } from "./main/sponsors/sponsors.component";
import { PrizesComponent } from "./main/prizes/prizes.component";
import { DatesComponent } from "./main/dates/dates.component";
import {WinnersComponent} from "./main/winners/winners.component";

const appRoutes: Routes = [
  {path : '',
  component: MainComponent,
  children : [
    {path: '',
    component: HomeComponent},
    {path: 'about', component: AboutComponent},
    {path: 'news', component: NewsComponent},
    {path: 'winners', component: WinnersComponent},
    {path: 'dates', component: DatesComponent},
    {path: 'downloads', component: DownloadsComponent},
    {path: 'sponsors', component: SponsorsComponent},
    {path: 'prizes', component: PrizesComponent}
  ]}
];


@NgModule({
  imports: [RouterModule.forRoot(appRoutes)]
})

export class AppRouting {}
