import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  public menu: any[] =  [
    {Items: [{Link: '/', Name: 'Home'}]},
    {Items: [{Link: '/news', Name: 'News'}]},
    {Items: [{Link: '/winners', Name: 'Winners'}]},
    {Items: [{Link: '/downloads', Name: 'Downloads'}]},
    {Items: [{Link: '/dates', Name: 'Event Calendar'}]},
    {Items: [{Link: '/sponsors', Name: 'Sponsors'}]},
    {Items: [{Link: '/prizes', Name: 'Prizes'}]},
    {Items: [{Link: '/about', Name: 'About Us'}]}
    ];

  constructor() { }

  ngOnInit() {
  }

}
