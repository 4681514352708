import { Subject } from "rxjs";
import { Http, RequestOptions, Headers, Response } from "@angular/http";
import { Router } from "@angular/router";
import { API_URL } from "../../../config/app.config";
import { Injectable } from "@angular/core";
import { FileModel } from "./file.model";
@Injectable()
export class FileService {

  imageListUpdated: Subject<any[]> = new Subject<any[]>();
  fileListUpdated: Subject<FileModel[]> = new Subject<FileModel[]>();
  private filesLoaded : boolean = false;
  private files: FileModel[];
  private options: RequestOptions;

  constructor(private http: Http, private router: Router) {
    this.options = new RequestOptions();
    // this.options.headers = new Headers({'Content-type': 'application/json'});
  }

  getPictureList() {
    this.http.get(API_URL + '/slideshow', this.options)
      .subscribe((response: Response)=> {
        let data: any[] = [];
        for (let file of response.json().files)
        {
          data.push({src: 'http://www.sciencefairfarnorth.ac.nz/assets/img/slides/' + file.FileName, label: '', content: ''})
        }
        this.imageListUpdated.next(data);
      })
  }

  getFileList() {
    if (this.filesLoaded)
    {
      this.fileListUpdated.next(this.files.slice());
    }
    else {
      this.http.get(API_URL + '/downloads', this.options)
        .subscribe((data: Response)=> {
          this.files = data.json().files;
          this.fileListUpdated.next(this.files.slice());
          this.filesLoaded = true;
        })
    }
  }
}
