import { Injectable } from "@angular/core";
import { Http, RequestOptions, Headers, Response } from "@angular/http";
import { Router } from "@angular/router";
import { API_URL } from "../../../config/app.config";
// import { ContentModel } from "../models/content.model";
import { Subject } from "rxjs";

@Injectable()
export class DataService {

  dataServiceArrived: Subject<{Type: string; Content: string}[]> = new Subject();

  private options: RequestOptions;
  private data : {Type:string, Content: string}[] = [];
  // public dataLoaded: boolean = false;

  constructor(private http: Http, private router: Router) {
    this.options = new RequestOptions();
    this.options.withCredentials = true;
    this.options.headers = new Headers({'Content-type': 'application/json'});

  }

  getContentTopics(topic) {

    if (this.data.filter((data) => {return data.Type == topic}).length > 0)
    {
      this.dataServiceArrived.next(this.data.slice());
    }
    else{
      this.http.get(API_URL + '/contentfiles/v2/' + topic, this.options)
        .subscribe((response: Response) => {
          this.data.splice(this.data.indexOf(this.data.filter((data) => {return data.Type == topic})[0]),1);
          this.data.push(response.json().ContentUpdate[topic]);
          this.dataServiceArrived.next(this.data.slice());
        });
    }
  }

  getWinnerdata() {
    return this.http.get('/assets/winners.component.data', this.options)
  }

  // getContentList() {
  //   return
  // }

  // getContent()
  // {
  //   if (!this.dataLoaded)
  //   {
  //     this.getContentList();
  //   }
  //   else {
  //     this.dataServiceArrived.next(this.data.slice());
  //   }
  // }

}
