import { Injectable } from "@angular/core";
import { Http, RequestOptions, Headers, Response } from "@angular/http";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { API_URL } from "../../../config/app.config";
import { DateModel } from "./date.model";
@Injectable()
export class DateService {

  private options: RequestOptions;
  private dates: DateModel[] = [];
  private isLoaded: boolean = false;

  public dateSubject: Subject<DateModel[]> = new Subject();

  constructor(private http: Http, private router: Router) {
    this.options = new RequestOptions();
    this.options.withCredentials = true;
    this.options.headers = new Headers({'Content-type': 'application/json'});
  }

  public getDates() {
    if (this.isLoaded) {
      this.dateSubject.next(this.dates.slice());
    }
    else {
      this.http.get(API_URL + '/events', this.options)
        .subscribe((response: Response) =>
        {
          this.dates = response.json().Dates;
          this.dateSubject.next(this.dates.slice());
          this.isLoaded = true;
        }
      )
    }
  }


}
