export class DateModel
{
  DateDay: string;
  DateMonth: string;
  DateYear: string;
  Date: Date;
  Topic: string;
  Content: string;
  Tba: number;
}
