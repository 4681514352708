import {Component, OnInit} from '@angular/core';
import {DataService} from "../services/dataservice.service";

@Component({
	selector: 'app-about',
	templateUrl: './about.component.html',
	styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

	public intro: string = '';
	public body: string = '';
	public heading: string = '';
	public defaultHeading: string = 'About Us';

	constructor(private dataService: DataService)
	{

		this.dataService.dataServiceArrived.subscribe(
			(data) =>
			{
				if (data.filter((data) =>
				{
					return data.Type == 'title-about'
				}).length > 0) this.heading = data.filter((data) =>
				{
					return data.Type == 'title-about'
				})[0].Content.replace(/(<([^>]+)>)/gi, "");
				if (data.filter((data) =>
				{
					return data.Type == 'intro-about'
				}).length > 0) this.intro = data.filter((data) =>
				{
					return data.Type == 'intro-about'
				})[0].Content;
				if (data.filter((data) =>
				{
					return data.Type == 'body-about'
				}).length > 0) this.body = data.filter((data) =>
				{
					return data.Type == 'body-about'
				})[0].Content;
			}
		)
	}

	ngOnInit()
	{
		this.dataService.getContentTopics('title-about');
		this.dataService.getContentTopics('intro-about');
		this.dataService.getContentTopics('body-about');
	}


}
