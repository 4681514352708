import {Component, OnInit} from '@angular/core';
import {DataService} from "../services/dataservice.service";
import {Http} from "@angular/http";
import {HttpResponse} from "@angular/common/http";

@Component({
    selector: 'app-winners',
    templateUrl: './winners.component.html',
    styleUrls: ['./winners.component.css']
})
export class WinnersComponent implements OnInit {

    public intro: string = '';
    public body: string = '';
	public heading: string = '';
	public defaultHeading: string = 'Winners';

    constructor(private dataService: DataService)
    {

        this.dataService.dataServiceArrived.subscribe(
            (data) =>
            {
                if (data.filter((data) =>
                    {
                        return data.Type == 'intro-winners'
                    }).length > 0) this.intro = data.filter((data) =>
                {
                    return data.Type == 'intro-winners'
                })[0].Content;
                if (data.filter((data) =>
                    {
                        return data.Type == 'title-winners'
                    }).length > 0) this.heading = data.filter((data) =>
                {
                    return data.Type == 'title-winners'
                })[0].Content.replace(/(<([^>]+)>)/gi, "");
            }
        )
        this.dataService.getWinnerdata()
            .subscribe((data) =>
            {
                let message: string;
                message = data['_body'];
                let prep = message.split("\n");
                let tableActive: boolean = false;
                let firstRow: boolean = false;
                let output: string = '';
                prep.forEach(row =>
                {
                    if (row.search(",") > 0)
                    {
                        //is a data or header. either way, table
                        if (!tableActive)
                        {
                            output += "<table class='table'>\n";
                            tableActive = true;
                            firstRow = true;
                        }
                        if (firstRow)
                        {
                            output += "\t<tr><th>" + row.split(",").join("</th><th>") + "</th></tr>\n";
                        }
                        else
                        {
                            output += "\t<tr><td>" + row.split(",").join("</td><td>") + "</td></tr>\n";
                        }
                        firstRow = false;
                    }
                    else
                    {
                        // is a heading
                        // kill table if active
                        if (tableActive)
                        {
                            output += "</table>\n";
                            tableActive = false;
                        }
                        output += "<h2>" + row + "</h2>\n";
                    }
                })
                if (tableActive)
                    output += "</table>\n";
                this.body = output;
                // console.log(message);
            })

    }

    ngOnInit()
    {
        this.dataService.getContentTopics('title-winners');
        this.dataService.getContentTopics('intro-winners');
        // this.dataService.getContentTopics('body-news');
    }

}
