import {Component, OnInit} from '@angular/core';
import {DataService} from "../services/dataservice.service";
import {DateService} from "../services/dateservice.service";
import {DateModel} from "../services/date.model";

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

	public dates: DateModel[] = [];
	public intro: string = '';
	public heading: string = '';
	public defaultHeading: string = 'Loading...';
	public sponsorMessage: string = '';
	private slides: any[];

	constructor(private dataService: DataService, private dateService: DateService)
	{

		this.dataService.dataServiceArrived.subscribe(
			(data) =>
			{
				if (data.filter((data) =>
				{
					return data.Type == 'intro-home'
				}).length > 0) this.intro = data.filter((data) =>
				{
					return data.Type == 'intro-home'
				})[0].Content;

				if (data.filter((data) =>
				{
					return data.Type == 'title-home'
				}).length > 0) this.heading = data.filter((data) =>
				{
					return data.Type == 'title-home'
				})[0].Content.replace(/(<([^>]+)>)/gi, "");

				if (data.filter((data) =>
				{
					return data.Type == 'sponsor-message'
				}).length > 0) this.sponsorMessage = data.filter((data) =>
				{
					return data.Type == 'sponsor-message'
				})[0].Content;
			}
		)
		this.dateService.dateSubject.subscribe(
			(dates: DateModel[]) =>
			{
				this.dates = dates;
			}
		)
	}

	ngOnInit()
	{
		this.slides = [{src: './assets/img/bay_1200_500.jpg', label: '', content: ''}];
		this.dataService.getContentTopics('title-home');
		this.dataService.getContentTopics('intro-home');
		this.dataService.getContentTopics('sponsor-message');
		this.dateService.getDates();
	}

	addSlide()
	{
		this.slides.push({src: 'https://lorempixel.com/1000/500?r=1', label: 'hahamore', content: 'bonkmore'});
	}

}
