import {Component, OnInit} from '@angular/core';
import {DataService} from "../services/dataservice.service";

@Component({
	selector: 'app-news',
	templateUrl: './news.component.html',
	styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {

	public intro: string = '';
	public heading: string = '';
	public defaultHeading: string = 'News';
	public body: string = '';

	constructor(private dataService: DataService)
	{

		this.dataService.dataServiceArrived.subscribe(
			(data) =>
			{
				if (data.filter((data) =>
				{
					return data.Type == 'title-news'
				}).length > 0) this.heading = data.filter((data) =>
				{
					return data.Type == 'title-news'
				})[0].Content.replace(/(<([^>]+)>)/gi, "");
				if (data.filter((data) =>
				{
					return data.Type == 'intro-news'
				}).length > 0) this.intro = data.filter((data) =>
				{
					return data.Type == 'intro-news'
				})[0].Content;
				if (data.filter((data) =>
				{
					return data.Type == 'body-news'
				}).length > 0) this.body = data.filter((data) =>
				{
					return data.Type == 'body-news'
				})[0].Content;
			}
		)
	}

	ngOnInit()
	{
		this.dataService.getContentTopics('title-news');
		this.dataService.getContentTopics('intro-news');
		this.dataService.getContentTopics('body-news');
	}

}
